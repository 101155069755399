import * as React from "react";
import "../../gatsby-browser.js";
import Navbar from "../component/Navbar/Navbar";
import Footer from "../component/Footer/Footer";
import { motion } from "framer-motion";
import { lan } from "../lan/service";
import Services from "../static/img/img/work.png";
// import { lan_data } from "../component/Local/local";
import Illustration from "../static/img/searchEngineOptimization/seo-banner.svg";
import Article from "../static/img/searchEngineOptimization/article.svg"
import Blog from "../static/img/searchEngineOptimization/blog.svg"
import Blogging from "../static/img/searchEngineOptimization/blogging.svg"
import Description from "../static/img/searchEngineOptimization/description.svg"
import EffectiveLanding from "../static/img/searchEngineOptimization/effective-landing.svg"
import Header from "../static/img/searchEngineOptimization/header.svg"
import ImageOptimization from "../static/img/searchEngineOptimization/image-optimization.svg"
import Informative from "../static/img/searchEngineOptimization/informative.svg"
import Platform from "../static/img/searchEngineOptimization/platforms.svg"
import Press from "../static/img/searchEngineOptimization/press.svg"
import Site from "../static/img/searchEngineOptimization/site.svg"
import SocialMedia from "../static/img/searchEngineOptimization/socialMedia.svg"
import Title from "../static/img/searchEngineOptimization/title.svg"
import MetaTags from "../component/MetaTags.jsx";

class Seo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      loading: true,
      name: [],
      email: [],
      phone: [],
      message: [],
    };

    this.lan_data = "";
  }

  getName = (e) => {
    this.setState({
      name: e.target.value,
    });
  };

  getEmail = (e) => {
    this.setState({
      email: e.target.value,
    });
  };

  getPhone = (e) => {
    this.setState({
      phone: e.target.value,
    });
  };

  getMessage = (e) => {
    this.setState({
      message: e.target.value,
    });
  };

  render() {
    const pageVariants = {
      initial: {
        opacity: 0,
      },
      in: {
        opacity: 1,
      },
      out: {
        opacity: 0,
      },
    };

    const { data, loading } = this.state;

    return (
      <>
        <Navbar />
        <motion.div
          initial="initial"
          animate="in"
          exit="out"
          variants={pageVariants}
        >
          <section id="mobile-tech-banner" className="howwedo">
            <div className="banner-text">
              <h1 className="highlighted-text">
                Make with our cutting-edge
                <br />
                Search Engine Optimization
              </h1>
              <p>{lan.en["seo"]["head"][0]}</p>
            </div>
            <img src={Illustration} alt="" />
          </section>

          <div className="container">
            <div className="row about-extra">
              <div className="mobile-desc" align={"justify"}>
                <h2 align={"center"} className="highlighted-text">
                  {" "}
                  About <span className="light-text">SEO</span>{" "}
                </h2>

                <p className={"service_about"}>{lan.en["seo"]["about"][0]}</p>
              </div>
            </div>
          </div>

          <section id="services" className="advance">
            <header className="section-header">
              <h3 className="light-text"> End-to-End Mobile Development </h3>
            </header>
            <br />

            <div className="pointers-container">
              <div className="wow bounceInUp " data-wow-duration="1.4s">
                <div className="row">
                  <div className="wow bounceInUp " data-wow-duration="1.4s">
                    <div className="box blank_service">
                      <p>
                        <div className={"row"}>
                          <div className={"col-sm-12"}>
                            <span id={"one_point"}> 1. </span>{" "}
                            {lan.en["seo"]["service"][0]}
                          </div>
                        </div>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* ========================================  type =========================================== */}

          <section id="services" className="processing">
              <div className={"row"}>
                <div align={"center"}>
                  <br /> <br />
                  <br />
                  <h2 className="light-text">Technologies We Use</h2>
                </div>
                <h4 className="highlighted-text" align="center">
                On-page or on-site optimization
                </h4>
                <br /> <br />
                <div className="tech-container">
                  <div className="box ">
                    <div align="center">
                      <img
                        src={Description}
                        alt=""
                        className="img-fluid"
                        width="100"
                      />
                    </div>{" "}
                    <br />
                    <div align="center">
                      <h4 className="tech_head">Description Tags</h4>
                    </div>
                  </div>
                  <div className="box ">
                    <div align="center">
                      <img
                        src={Title}
                        alt=""
                        className="img-fluid"
                        width="100"
                      />
                    </div>{" "}
                    <br />
                    <div align="center">
                      <h4 className="tech_head">Title Tags</h4>
                    </div>
                  </div>{" "}
                  <div className="box ">
                    <div align="center">
                      <img src={ImageOptimization} alt="" className="img-fluid" width="100" />
                    </div>{" "}
                    <br />
                    <div align="center">
                      <h4 className="tech_head">Image Optimization</h4>
                    </div>
                  </div>{" "}
                  <div className="box ">
                    <div align="center">
                      <img
                        src={EffectiveLanding}
                        alt=""
                        className="img-fluid"
                        width="100"
                      />
                    </div>{" "}
                    <br />
                    <div align="center">
                      <h4 className="tech_head">Effective Landing Page</h4>
                    </div>
                  </div>{" "}
                  <div className="box ">
                    <div align="center">
                      <img
                        src={Site}
                        alt=""
                        className="img-fluid"
                        width="100"
                      />
                    </div>{" "}
                    <br />
                    <div align="center">
                      <h4 className="tech_head">Site Structure And Navigation</h4>
                    </div>
                  </div>{" "}
                  <div className="box ">
                    <div align="center">
                      <img
                        src={Header}
                        alt=""
                        className="img-fluid"
                        width="100"
                      />
                    </div>{" "}
                    <br />
                    <div align="center">
                      <h4 className="tech_head">Header Tags</h4>
                    </div>
                  </div>
                  <div className="box ">
                    <div align="center">
                      <img
                        src={Informative}
                        alt=""
                        className="img-fluid"
                        width="100"
                      />
                    </div>{" "}
                    <br />
                    <div align="center">
                      <h4 className="tech_head">Unique and informative content</h4>
                    </div>
                  </div>
                </div>

                <h4 className="highlighted-text" align="center">
                Off-page or off-site optimization
                </h4>
                <br /> <br />
                <div className="tech-container">
                  <div className="box ">
                    <div align="center">
                      <img
                        src={SocialMedia}
                        alt=""
                        className="img-fluid"
                        width="100"
                      />
                    </div>{" "}
                    <br />
                    <div align="center">
                      <h4 className="tech_head">Integrating Social Media</h4>
                    </div>
                  </div>
                  <div className="box ">
                    <div align="center">
                      <img
                        src={Platform}
                        alt=""
                        className="img-fluid"
                        width="100"
                      />
                    </div>{" "}
                    <br />
                    <div align="center">
                      <h4 className="tech_head">Social Media Platforms</h4>
                    </div>
                  </div>{" "}
                  <div className="box ">
                    <div align="center">
                      <img src={Blog} alt="" className="img-fluid" width="100" />
                    </div>{" "}
                    <br />
                    <div align="center">
                      <h4 className="tech_head">Blog Commenting</h4>
                    </div>
                  </div>{" "}
                  <div className="box ">
                    <div align="center">
                      <img src={Blogging} alt="" className="img-fluid" width="100" />
                    </div>{" "}
                    <br />
                    <div align="center">
                      <h4 className="tech_head">Blogging </h4>
                    </div>
                  </div>{" "}
                  <div className="box ">
                    <div align="center">
                      <img
                        src={Article}
                        alt=""
                        className="img-fluid"
                        width="100"
                      />
                    </div>{" "}
                    <br />
                    <div align="center">
                      <h4 className="tech_head">Submitting Articles</h4>
                    </div>
                  </div>{" "}
                  <div className="box ">
                    <div align="center">
                      <img
                        src={Press}
                        alt=""
                        className="img-fluid"
                        width="100"
                      />
                    </div>{" "}
                    <br />
                    <div align="center">
                      <h4 className="tech_head">Press Release</h4>
                    </div>
                  </div>
                </div>
            </div>
          </section>

          {/* <section id="services" className="section-bg processing">
            <div className={"container"}>
              <div className={"row"}>
                <div align={"center"}>
                  <h2 className={"process_head"}> Technologies We Use </h2>
                </div>
                <br />
                <br />
                <br />
                <div className={"col-sm-12"}>
                  <div className={"table-responsive"}>
                    <table className="table ">
                      <thead></thead>

                      <tbody>
                        <tr align={"center"}>
                          <th scope="row">On-page or on-site optimization: </th>

                          <td>Description tags</td>
                          <td>Title tags</td>
                          <td>Image optimization</td>
                          <td>Effective landing page</td>
                          <td>Site structure and navigation</td>
                          <td>Header tags</td>
                          <td>Unique and informative content</td>
                        </tr>
                      </tbody>

                      <tbody>
                        <tr align={"center"}>
                          <th scope="row">
                            Off-page or off site optimization:{" "}
                          </th>

                          <td>Integrating social media</td>
                          <td>Social media platforms</td>
                          <td>Blog commenting</td>
                          <td>Blogging</td>
                          <td>Submitting articles</td>
                          <td>Press release</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </section> */}

          <br />
          <br />

          <Footer />
        </motion.div>
        <MetaTags title={"SEO | Koderbox"} />
      </>
    );
  }
}

export default Seo;
